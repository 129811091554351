import React, { useMemo, useCallback, useState, ReactElement } from 'react';
import { createPortal } from 'react-dom';

import { DrawerContainer, Backdrop } from './styles';
import { AppDrawerContextProvider } from '../../../AppDrawerContext';

export const BaseDrawer: React.FC<BaseDrawerProps> = ({ isOpen, toggle, drawer, anchor = 'left' }) => {
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const close = useCallback(() => {
    let closeTimeout = 0;

    setIsClosing(true);
    closeTimeout = window.setTimeout(() => {
      toggle();
      setIsClosing(false);
    }, 300);

    return () => {
      clearTimeout(closeTimeout);
    };
  }, [toggle]);

  const context = useMemo(() => ({ close }), [close]);

  return createPortal(
    <>
      {isOpen && <Backdrop isClosing={isClosing} onClick={context.close} />}
      <AppDrawerContextProvider value={context}>
        <DrawerContainer active={isOpen && !isClosing} anchor={anchor}>
          {drawer}
        </DrawerContainer>
      </AppDrawerContextProvider>
    </>,
    document.body,
  );
};

export interface BaseDrawerProps {
  drawer: ReactElement;
  isOpen: boolean;
  anchor?: 'left' | 'right';
  toggle: () => void;
}
