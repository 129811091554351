import React, { useEffect, useState } from 'react';
import {
  ApolloLink,
  ApolloClient,
  NormalizedCacheObject,
  ApolloProvider as Provider,
  createHttpLink,
} from '@apollo/client';
import { errorLink } from './links/errorLink';

import { cache } from './cache';
import { authLink } from './links/authLink';
import { useAuthTokenContext } from '../AuthTokenProvider';
import config from 'app/global/config';

export const ApolloProvider = ({ children }: { children: React.ReactNode }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const { getToken } = useAuthTokenContext();

  useEffect(() => {
    console.info('init apollo...');
    const link = ApolloLink.from([authLink(getToken), errorLink(), createHttpLink({ uri: config.graphQLEndpoint })]);
    const client = new ApolloClient({ link, cache });
    setClient(client);

    return () => {
      cache.reset();
    };
  }, [getToken]);

  return client ? <Provider client={client}>{children}</Provider> : null;
};
