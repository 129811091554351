import { colors } from 'assets/css/variables';
import styled from 'styled-components';
import { AppBarItem } from '../AppBarItem';

export const Container = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  height: 65px;
  display: flex;
  justify-content: flex-start;
`;

export const MenuAppBarItem = styled(AppBarItem)`
  padding: 0;
`;

export const MenuButton = styled.button`
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
  border: 0;
  outline: 0;
  padding: 0 24px;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.97);
  }
`;
