import { colors } from 'assets/css/variables';
import styled, { css } from 'styled-components';

export const AppBarItem = styled.div<{
  borderRight?: boolean;
  borderLeft?: boolean;
  align?: 'left' | 'right';
}>`
  height: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${colors.black};

  ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left: 1px solid ${colors.lightGrey};
    `}

  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid ${colors.lightGrey};
    `}

  ${({ align }) =>
    align === 'right' &&
    css`
      margin-left: auto;
    `}
`;
