import React from 'react';

export const MenuIcon = ({ title, className }: IconProps) => (
  <svg
    className={className}
    height="25"
    width="25"
    data-name="Laag 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <title>{title}</title>
    <path d="M28,13.5H2a1.5,1.5,0,0,0,0,3H28a1.5,1.5,0,0,0,0-3Z" />
    <path d="M2,4H28a1.5,1.5,0,0,0,0-3H2A1.5,1.5,0,0,0,2,4Z" />
    <path d="M28,26.24H2a1.5,1.5,0,0,0,0,3H28a1.5,1.5,0,0,0,0-3Z" />
  </svg>
);

export interface IconProps {
  title?: string;
  className?: string;
}
