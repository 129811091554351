import React from 'react';
import { TopNav } from '../TopNav';
import { Container, Content } from './styles';

export const ViewLayout: React.FC = ({ children }) => {
  return (
    <Container>
      <TopNav />
      <Content>{children}</Content>
    </Container>
  );
};
