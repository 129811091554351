import { useAuthTokenContext } from 'app/global/context/AuthTokenProvider';
import React from 'react';
import { Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, path, ...rest }: PrivateRouteProps) => {
  const { authenticated } = useAuthTokenContext();

  const render = (props: any) => (authenticated ? <Component {...props} /> : null);
  return <Route path={path} render={render} {...rest} />;
};

export interface PrivateRouteProps {
  component: React.FC;
  path: string;
  [n: string]: any;
}
