import { colors } from 'assets/css/variables';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

export const Backdrop = styled.div<{ isClosing: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 0.3s;
  opacity: ${({ isClosing }) => (isClosing ? 0 : 1)};
  transition: opacity 0.3s;
`;

export const DrawerContainer = styled.div<{ active: boolean; anchor: 'left' | 'right' }>`
  position: fixed;
  top: 0;
  left: ${({ anchor }) => (anchor === 'left' ? 0 : 'auto')};
  right: ${({ anchor }) => (anchor === 'right' ? 0 : 'auto')};
  height: 100%;
  width: 375px;
  z-index: 20;
  background-color: ${colors.white};
  transition: transform 0.2s;
  transform: ${({ active, anchor }) =>
    active ? 'translateX(0)' : `translateX(${anchor === 'left' ? '-375px' : '375px'})`};
`;
