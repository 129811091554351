import React from 'react';

export const CrossIcon = ({ className }: { className?: string }) => (
  <svg id="Laag_2" data-name="Laag 2" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <title>Close</title>
    <path
      className="cls-1"
      d="M17.83,15l7-7A2,2,0,1,0,22,5.18l-7,7-7-7A2,2,0,0,0,5.18,8l7,7-7,7A2,2,0,0,0,6.6,25.4,2,2,0,0,0,8,24.82l7-7,7,7a2,2,0,0,0,2.83,0,2,2,0,0,0,0-2.83Z"
    />
  </svg>
);
