import styled, { css, keyframes } from 'styled-components';
import { colors } from 'assets/css/variables';

export type ButtonProps = {
  secondary?: boolean;
  fullWidth?: boolean;
};

const primaryButton = css`
  background-color: ${colors.primary};
  color: ${colors.white};
  border: none;
`;

const secondaryButton = css`
  background-color: ${colors.white};
  color: ${colors.lightBlue};
  border: 1px solid ${colors.lightBlue};
`;

export const StyledButton = styled.button<ButtonProps>`
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease, filter 0.3s ease;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  height: 52px;
  line-height: 52px;
  padding: 0 16px;
  appearance: none;
  outline: 0;
  cursor: pointer;

  &:hover {
    filter: brightness(0.95);
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      display: block;
    `}

  ${({ secondary }) => (!secondary ? primaryButton : secondaryButton)};
`;

const animateRipple = keyframes`
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(10);
    }
`;

export const RippleContainer = styled.span`
  & > span {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);

    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;

    animation: ${animateRipple} 1.25s;
    opacity: 0;
  }
`;
