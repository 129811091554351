import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { CriticalErrorView } from 'components/views/CriticalErrorView';
import { ViewLayout } from '../ViewLayout';
import { PrivateRoute } from '../PrivateRoute';

export const AppRouter = () => {
  return (
    <ErrorBoundary component={CriticalErrorView}>
      <ViewLayout>
        <Suspense fallback="loading...">
          <Switch>
            <PrivateRoute path="/stock" component={React.lazy(() => import('components/views/Stock'))} />
            <PrivateRoute path="/finances" component={React.lazy(() => import('components/views/Finances'))} />
            <PrivateRoute path="/vault" component={React.lazy(() => import('components/views/Vault'))} />
            <Redirect to="/stock" />
          </Switch>
        </Suspense>
      </ViewLayout>
    </ErrorBoundary>
  );
};
