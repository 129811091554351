import { css } from 'styled-components';

export const colors = {
  white: '#fff',
  textGrey: '#8C8C8C',
  grey: '#4F4F4F',
  midGrey: '#2F2F2F',
  darkGrey: '#1E2022',
  lightGrey: '#f7f7f7',
  lightGrey2: '#dae1e7',
  linearGradient: '#1D1D1D',
  lightBlue: '#0693C9',
  midBlue: '#0485BA',
  darkBlue: '#001F4B',
  green: '#45BE41',
  orange: '#f39902',
  red: '#DB3A2A',
  black: '#333333',

  primary: '#4abdac',
  blackTransparent80: 'rgba(0, 0, 0, 0.87)',
  blackTransparent20: 'rgba(0, 0, 0, 0.23)',
};

const mediaQuery = (minWidth: number) => `@media screen and (min-width: ${minWidth}em)`;

export const screens = {
  sm: mediaQuery(360 / 16),
  md: mediaQuery(768 / 16),
  lg: mediaQuery(992 / 16),
  xl: mediaQuery(1200 / 16),
};

export const ripple = css`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
`;
