import React, { createContext, useContext, useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import config from 'app/global/config';

const AuthTokenContext = createContext<AuthTokenContextValue | null>(null);

export const useAuthTokenContext = () => {
  const context = useContext(AuthTokenContext);

  if (!context) throw new Error('useAuthTokenContext can only be used inside AuthTokenContext');

  return context as AuthTokenContextValue;
};

/**
 * Authentication context provider which manages the active JWT token
 * - It exposes the active JWT token and a static getter
 * - It forces the user to login on initialization if he's not
 */
export const AuthTokenProvider: React.FC<any> = ({ children }) => {
  const [token, setToken] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const tokenRef = useRef<string>(token);
  const active = useRef(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function fn() {
      try {
        active.current = true;
        const result = await Auth.currentSession();
        tokenRef.current = result.getAccessToken().getJwtToken();
        const userInfo = await Auth.currentUserInfo();
        setUsername(userInfo.username);

        setToken(tokenRef.current);
        setAuthenticated(result.isValid());
      } catch (err) {
        console.log(err);
        window.location.replace(config.authUrl);
      } finally {
        active.current = false;
      }
    }

    const queryParams = new URLSearchParams(location.search);
    if (!active.current && !authenticated && !queryParams.get('code')) fn();
  }, [authenticated, history, location.search]);

  const getToken = useCallback(() => tokenRef.current, []);
  const value = useMemo(
    () => ({
      authenticated,
      token,
      username,
      getToken,
      signout: async () => {
        await Auth.signOut();
        history.push(config.authUrl);
      },
    }),
    [token, username, authenticated, getToken, history],
  );

  return <AuthTokenContext.Provider value={value}>{children}</AuthTokenContext.Provider>;
};

export interface AuthTokenContextValue {
  token: string;
  username: string;
  getToken: () => string;
  authenticated: boolean;
  signout: () => Promise<any>;
}
