import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';
import { ApolloLink } from '@apollo/client/core';
import config from 'app/global/config';

export const authLink = (tokenFn: () => string) => {
  return createAuthLink({
    url: config.graphQLEndpoint,
    region: config.apiRegion,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: tokenFn,
    },
  }) as unknown as ApolloLink;
};
