import React, { MutableRefObject, useCallback, useRef } from 'react';
import { StyledButton, RippleContainer } from './styles';
import ReactDOM from 'react-dom';
import { Spinner } from '../Spinner';
import { colors } from 'assets/css/variables';

const Button = ({ onClick, loading, children, secondary, ...rest }: ButtonProps) => {
  const rippleContainerRef = useRef() as React.RefObject<HTMLButtonElement>;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const button = e.currentTarget;
      const rect = button.getBoundingClientRect();
      const x = e.pageX - rect.left;
      const y = e.pageY - rect.top;

      if (rippleContainerRef.current) ReactDOM.unmountComponentAtNode(rippleContainerRef.current);
      const span = React.createElement('span', { style: { left: x, top: y } });
      ReactDOM.render(span, rippleContainerRef.current);

      !!onClick && onClick(e);
    },
    [rippleContainerRef, onClick],
  );

  return (
    <StyledButton {...rest} secondary={secondary} onClick={handleClick} disabled={rest.disabled || loading}>
      {loading ? <Spinner scale={0.6} color={colors.white} /> : children}
      <RippleContainer ref={rippleContainerRef} />
    </StyledButton>
  );
};

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  secondary?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  ref?: MutableRefObject<any>;
}

export default Button;
