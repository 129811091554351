import React from 'react';
import SentryLogger from 'app/global/util/SentryLogger';

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    SentryLogger.error(error, info);
  }

  render() {
    const { component: Component } = this.props;
    if (this.state.hasError) return <Component />;
    return this.props.children;
  }
}

export interface ErrorBoundaryProps {
  component: React.FC;
}
