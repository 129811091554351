import { onError } from 'apollo-link-error';
import { ApolloLink } from '@apollo/client/core';
import SentryLogger from 'app/global/util/SentryLogger';

export const errorLink = () =>
  onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        SentryLogger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });

    if (networkError) {
      SentryLogger.error(`[Network error]: ${networkError}`);
    }

    console.error(response);
  }) as unknown as ApolloLink;
