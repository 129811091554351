import styled from 'styled-components/macro';
import { colors } from 'assets/css/variables';

export const Container = styled.div`
  background-color: ${colors.lightGrey};
  height: 100vh;
`;

export const Content = styled.div`
  height: calc(100vh - 67px);
  overflow-y: auto;
  position: relative;
  flex: 1;
`;
