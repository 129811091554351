import { colors } from 'assets/css/variables';
import Button from 'components/common/Button';
import { CrossIcon } from 'components/common/icons/CrossIcon';
import { NextAngle } from 'components/common/icons/NextAngle';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
`;

export const HeaderContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const Title = styled.h1`
  font-weight: normal;
  margin: 0;
  padding-left: 1rem;
`;

export const CloseButton = styled.button`
  width: 65px;
  height: 65px;
  background-color: ${colors.primary};
  border: 0;
  outline: 0;
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CloseIcon = styled(CrossIcon)`
  height: 2rem;
  width: 2rem;
  fill: ${colors.white};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const NavSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const NavItem = styled(Link)<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  color: ${colors.darkGrey};
  padding: 1rem 1rem;
  border-bottom: 1px solid ${colors.lightGrey};
  background-color: ${({ selected }) => (selected ? colors.lightGrey : undefined)};
  transition: color 0.2s;

  &:hover {
    color: ${colors.primary};
  }
`;

export const StyledArrowIcon = styled(NextAngle)`
  height: 14px;
  width: 16px;
  color: ${colors.midBlue};
`;

export const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Username = styled.div`
  text-align: center;
`;

export const LogoutButton = styled(Button)`
  box-sizing: border-box;
  margin: 1rem;
  padding: 0 2rem;
  width: calc(100% - 2rem);
`;
