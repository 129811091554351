import { colors } from 'assets/css/variables';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const HomeLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.black};
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: normal;
  padding-left: 0.75rem;
`;
