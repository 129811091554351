import { InMemoryCache } from '@apollo/client';

export const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    GroceryList: {
      keyFields: ['storeId'],
      fields: {
        items: {
          merge: overwriteMerger,
        },
      },
    },
  },
});

function overwriteMerger(_: any, newVal: any) {
  return newVal;
}
