import * as Sentry from '@sentry/browser';
import { throttle } from 'throttle-debounce';

class SentryLogger {
  private static throttle = throttle(1000, (error: any, extras: Extras = {}) => {
    Sentry.withScope((scope: Sentry.Scope) => {
      if (extras) {
        scope.setExtras(extras);
      }
      Sentry.captureException(error);
      console.error(`${error}\n${JSON.stringify(extras)}`);
    });
  });

  static init() {
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'REPLACE_ME',
      });
    }
  }

  static setUser(user: Sentry.User) {
    Sentry.configureScope((scope: Sentry.Scope) => {
      scope.setUser(user);
    });
  }

  static error(error: any, extras: Extras = {}) {
    SentryLogger.throttle(error, extras);
  }
}

interface Extras {
  [key: string]: any;
}

export default SentryLogger;
