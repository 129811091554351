import { createContext, useContext } from 'react';

interface AppDrawerContextValues {
  close: () => void;
}

const AppDrawerContext = createContext({} as AppDrawerContextValues);

export const useAppDrawerContext = () => {
  const context = useContext(AppDrawerContext);

  if (!context || !Object.keys(context).length)
    throw new Error('useAppDrawerContext can only be used inside the AppBar drawer');

  return context;
};

export const AppDrawerContextProvider = AppDrawerContext.Provider;
