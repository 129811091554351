import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';

import * as serviceWorker from './serviceWorker';
import App from 'App';
import config from 'app/global/config/index';

import 'assets/css/global.css';

Amplify.configure({
  Auth: {
    region: config.apiRegion,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
