import React from 'react';
import { useAppDrawerContext } from '../AppDrawerContext';

import {
  Container,
  Header,
  HeaderContent,
  CloseButton,
  CloseIcon,
  Content,
  NavSection,
  UserSection,
  Username,
  LogoutButton,
  NavItem,
  StyledArrowIcon,
  Title,
} from './styles';

const NAV_ITEMS: { label: string; href: string }[] = [
  { label: 'Boodschappen', href: '/stock' },
  { label: 'Financiën', href: '/finances' },
  { label: 'Documentenkluis', href: '/vault' },
];

export const NavigationDrawer = ({ userName, logo: Logo, onLogout }: NavigationDrawerProps) => {
  const path = window.location.pathname;
  const { close } = useAppDrawerContext();

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Logo />
          <Title>Bonzai</Title>
        </HeaderContent>
        <CloseButton onClick={close}>
          <CloseIcon />
        </CloseButton>
      </Header>
      <Content>
        <NavSection>
          {NAV_ITEMS.map((item) => (
            <NavItem key={item.label} to={item.href} selected={path.startsWith(item.href)} onClick={close}>
              {item.label}
              <StyledArrowIcon />
            </NavItem>
          ))}
        </NavSection>
        <UserSection>
          <Username>{userName}</Username>
          <LogoutButton onClick={onLogout} secondary>
            Uitloggen
          </LogoutButton>
        </UserSection>
      </Content>
    </Container>
  );
};

export interface NavigationDrawerProps {
  userName?: string;
  logo: React.ComponentType;
  onLogout: () => void;
}
