import React from 'react';
import { IconProps } from './types';

export const NextAngle = ({ className }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="6" viewBox="0 0 4 6" fill="none" className={className}>
    <path
      d="M.073.427A.25.25 0 0 1 .427.073L3.104 2.75.427 5.427a.25.25 0 0 1-.354-.354L2.396 2.75.073.427z"
      fill="#4abdac"
    />
  </svg>
);
