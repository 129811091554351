import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';

import { Text } from './styles';

export const Clock = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setInterval(() => {
      setDate(new Date());
    }, 10000);
  }, []);
  return <Text>{format(date, 'HH:mm')}</Text>;
};
