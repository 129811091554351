import React from 'react';
import { Router } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { history } from 'app/global/history';
import { AppRouter } from 'components/navigation/AppRouter';
import { ApolloProvider } from 'app/global/context/ApolloProvider';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { CriticalErrorView } from 'components/views/CriticalErrorView';
import { AuthTokenProvider } from 'app/global/context/AuthTokenProvider';

const App = () => {
  return (
    <ErrorBoundary component={CriticalErrorView}>
      <Router history={history}>
        <AuthTokenProvider>
          <ApolloProvider>
            <AppRouter />
          </ApolloProvider>
        </AuthTokenProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default withAuthenticator(App);
