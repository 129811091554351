const config: Configuration = {
  env: 'test',
  graphQLEndpoint: 'https://tzkrlt45fneorhh7welpeppoii.appsync-api.eu-west-1.amazonaws.com/graphql',
  apiRegion: 'eu-west-1',
  userPoolId: 'eu-west-1_ImOb9qIst',
  userPoolWebClientId: '5il427nn3989lil5ugumeplodn',
  authUrl: `https://bonzai.auth.eu-west-1.amazoncognito.com/login?client_id=5il427nn3989lil5ugumeplodn&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${location.origin}/callback`,
};

export default config;

export interface Configuration {
  env: string;
  graphQLEndpoint: string;
  apiRegion: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authUrl: string;
}
