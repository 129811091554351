import React, { useState, useCallback, ReactElement } from 'react';
import { Container, MenuButton, MenuAppBarItem } from './styles';
import { BaseDrawer } from './components/BaseDrawer';
import { MenuIcon } from 'components/common/icons/MenuIcon';

export const AppBar: React.FC<AppBarProps> = ({ children, leftDrawer }) => {
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState<boolean>(false);

  const toggleLeftDrawer = useCallback(() => {
    setIsLeftDrawerOpen(!isLeftDrawerOpen);
  }, [isLeftDrawerOpen]);

  return (
    <Container>
      {leftDrawer ? (
        <>
          <MenuAppBarItem borderRight>
            <MenuButton onClick={toggleLeftDrawer}>
              <MenuIcon />
            </MenuButton>
          </MenuAppBarItem>
          <BaseDrawer isOpen={isLeftDrawerOpen} toggle={toggleLeftDrawer} drawer={leftDrawer} anchor="left" />
        </>
      ) : null}
      {children}
    </Container>
  );
};

export interface AppBarProps {
  leftDrawer?: ReactElement;
  notificationCount?: number | string;
}
