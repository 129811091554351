import React from 'react';
import { Ring } from './styles';

export const Spinner = ({ scale = 1, color }: { scale?: number; color?: string }) => (
  <Ring scale={scale} color={color}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </Ring>
);
