import { useAuthTokenContext } from 'app/global/context/AuthTokenProvider';
import React from 'react';
import { AppBar } from '../AppBar';
import { AppBarItem } from '../AppBarItem';
import { Clock } from '../Clock';
import { Logo } from '../Logo';
import { NavigationDrawer } from '../NavigationDrawer';
import { HomeLink, Title } from './styles';

export const TopNav = () => {
  const { signout, username } = useAuthTokenContext();

  return (
    <>
      <AppBar leftDrawer={<NavigationDrawer userName={username} onLogout={signout} logo={Logo} />}>
        <AppBarItem borderRight>
          <HomeLink to="/">
            <Logo />
            <Title>Bonzai</Title>
          </HomeLink>
        </AppBarItem>
        <AppBarItem align="right" borderLeft>
          <Clock />
        </AppBarItem>
      </AppBar>
    </>
  );
};
